import { Bubble, ChannelId, ConversationId } from 'common/types'
import React, { useCallback } from 'react'
import { FacilityId } from 'shared/types/utils'
import { get, push, set } from '../../firebaseMethods'

import { ADMIN_USER_ID, ConversationPath } from 'common/channels'
import { increment } from 'shared/firebase/serverValue'
import { refFullPath } from 'shared/firebase/typedMethods'
import { Contacts } from 'shared/types/live'
import { MessageInputForm } from '../../components/conversation/MessageInputForm'

interface Props {
  channelId: ChannelId
  facilityIds: Set<FacilityId>
}

export const GroupComposer: React.FC<Props> = ({ channelId, facilityIds }) => {
  const saveBubble = useCallback(
    async (bubble: Bubble): Promise<ConversationPath[]> => {
      return Promise.all(
        Array.from(facilityIds).map(async (facilityId) => {
          const contacts = await get(`contacts/${facilityId}`)
          return saveFacilityChannelBubble(
            channelId,
            contacts ?? {},
            facilityId,
            bubble,
          )
        }),
      )
    },
    [channelId, facilityIds],
  )

  return (
    <MessageInputForm
      saveBubble={saveBubble}
      isFileUploadEnabled
      senderId={ADMIN_USER_ID}
    />
  )
}

export async function saveFacilityChannelBubble(
  channelId: ChannelId,
  contacts: Contacts,
  facilityId: FacilityId,
  bubble: Bubble,
): Promise<ConversationPath> {
  // Convention, so that admin users can directly access this conversation
  const conversationId: ConversationId = channelId

  await Promise.all(
    Object.entries(contacts)
      .filter(([_contactId, contact]) => contact.type === 'user')
      .map(([contactId, _contact]) => {
        set(`discussions/${facilityId}/${contactId}/${channelId}`, {
          // always re-set, in case this is a new user
          conversationId,
          // posting user is not filtered out, an effect will reset unreadCount to 0
          unreadCount: increment(),
        })
      }),
  )

  // Update conversation AFTER unreadCount, so that it can be reset
  // to 0 for the bubble author
  const pushRef = await push(
    `conversations/${facilityId}/${conversationId}`,
    bubble,
  )

  return refFullPath(pushRef) as ConversationPath
}
