import { useContext } from 'react'
import { facilitiesContext } from '../contexts/FacilitiesProvider'
import { Select } from './Select'

export const FacilitySelector = () => {
  const { facilities, facilityId, setFacilityId } =
    useContext(facilitiesContext)

  return (
    <Select
      className="w-full rounded-none border-2 border-current bg-transparent"
      onChange={(event) => setFacilityId(event.target.value)}
      value={facilityId}
    >
      {Object.entries(facilities).map(([facilityId, facility]) => (
        <option value={facilityId} key={facilityId}>
          {facility.name}
        </option>
      ))}
    </Select>
  )
}
