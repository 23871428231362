import { Alert, alertTypeString } from 'shared/types/alert'
import { ms } from 'shared/utils/time'

export const ALERT_NOTIFICATION_TTL = ms(1, 'hour')

// 🌪️ i18n warning : update accordinly in live/src/common/alert.i18n.ts 🌪️
export function alertDisplayText(alert: Alert) {
  if (alert.type === 'BEEP') return `Bip ${alert.text.replace(/_/g, ' ')}`
  if (alert.keywords)
    return alert.keywords.map((keyword) => `"${keyword}"`).join(', ')
  return alertTypeString[alert.type]
}
