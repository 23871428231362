import { Trans } from '@lingui/macro'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { ms } from 'shared/utils/time'
import { cn } from 'shared/utils/web/cn'
import { Button } from '../../components/ui/Button'
import { useUser } from '../../components/UserProvider'
import { AlertContext } from './AlertContext'
import { trackVideoAction, TutorialCarousel } from './TutorialCarousel'

const LAST_POPUP_TIME = 'lastPopupTime'
const MIN_TIMEOUT = ms(8, 'hours')
const MAX_TIMEOUT = ms(96, 'hours')

const useTutorialTimer = (onTimer: () => void, isPopupShown: boolean) => {
  const timerRef = useRef<NodeJS.Timeout>()

  const scheduleNextPopup = useCallback(() => {
    if (isPopupShown) {
      return
    }

    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    const lastPopupTime = localStorage.getItem(LAST_POPUP_TIME)
    const elapsed = lastPopupTime
      ? Date.now() - parseInt(lastPopupTime, 10)
      : MAX_TIMEOUT

    const delay = Math.max(Math.random() * (MAX_TIMEOUT - elapsed), MIN_TIMEOUT)

    timerRef.current = setTimeout(() => {
      onTimer()
      localStorage.setItem(LAST_POPUP_TIME, Date.now().toString())
    }, delay)
  }, [onTimer, isPopupShown])

  useEffect(() => {
    if (!isPopupShown) {
      scheduleNextPopup()
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [scheduleNextPopup, isPopupShown])

  return scheduleNextPopup
}

const dialogRootElement = document.createElement('div')

document.body.appendChild(dialogRootElement)

export const TutorialPopupDialog: React.FC = () => {
  const [showPopupVideo, setShowPopupVideo] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const { hasRecentAlert } = useContext(AlertContext)
  const { uid, role } = useUser()

  const scheduleNextPopup = useTutorialTimer(() => {
    // don't show popup if there was a recent alert

    if (!hasRecentAlert()) {
      setShowPopupVideo(true)
    } else {
      // try again later
      scheduleNextPopup()
    }
  }, showPopupVideo)

  const handleExpand = () => setIsExpanded(true)

  const handleClose = async () => {
    if (role === 'AS') {
      await trackVideoAction(uid, 'POPUP_CLOSED', 'popup')
    }
    setIsExpanded(false)
    setShowPopupVideo(false)
    scheduleNextPopup()
  }

  return createPortal(
    <>
      {showPopupVideo && (
        <div
          className={cn(
            'fixed z-10 flex',
            isExpanded
              ? 'bottom-0 right-0 h-full w-full items-center justify-center bg-black bg-opacity-50'
              : 'bottom-3 right-3 items-end justify-end',
          )}
          onClick={(e) => {
            if (e.target === e.currentTarget) handleClose()
          }}
        >
          <div
            className={cn(
              'bg-background-dialog relative flex flex-col overflow-auto rounded-sm p-2 shadow-xl transition-all duration-500 ease-in-out',
              isExpanded
                ? 'max-h-[95dvh] max-w-[95vw]'
                : 'max-h-[64vh] max-w-[36vw]',
            )}
          >
            {!isExpanded && (
              <div
                className="absolute inset-0 z-20 cursor-pointer bg-transparent"
                onClick={handleExpand}
              />
            )}
            <TutorialCarousel isExpanded={isExpanded} source="popup" />
            <div className="relative z-30 flex flex-row pt-2">
              <Button
                onClick={handleClose}
                type="button"
                variant="outline"
                size="full"
              >
                <Trans>Fermer</Trans>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>,
    dialogRootElement,
  )
}
