import { FacilityId, UserId } from 'shared/types/utils'
import { ChannelId, ConversationId, User } from './types'

export type ConversationPath =
  `conversations/${FacilityId}/${ConversationId}/${string}`

export type SenderId = UserId | typeof ADMIN_USER_ID

export const osoChannelId = 'oso'
export const facilityChannelId = 'facility'
export const UPLOAD_IN_PROGRESS = '[UPLOAD_IN_PROGRESS]'
export const ADMIN_USER_ID = 'admin'

export const channelIds: ChannelId[] = [osoChannelId, facilityChannelId]

export function getSenderId(
  user: User,
  conversationId: ConversationId | undefined,
): SenderId {
  const { role, uid } = user

  // For 1-1 conversation
  if (conversationId === undefined) return uid

  // For channels, all admin users share the same senderId ADMIN_USER_ID below
  // to ensure a unified representation for messages sent by admins
  if (role === 'admin') {
    return ADMIN_USER_ID
  }

  return uid
}
