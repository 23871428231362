import { LiveHistory, LiveRoomState, TimeStamped } from 'common/roomState'
import React from 'react'
import { FirebaseKey } from 'shared/types/utils'
import { formatMsTime, ms2sec, sec2ms } from 'shared/utils/time'
import { useFirebase } from '../../hooks/useFirebase'

export const states = [
  'tv',
  'sleeping',
  'silence',
  'as',
  'yell',
  'speak',
  'need',
  'machine',
  'awake',
  'empty',
] as const // order defines precedence

export const stateData: Record<
  (typeof states)[number],
  {
    key: keyof Omit<Required<LiveRoomState>, 'timeStamp'>
    color: string
    title: string
  }
> = {
  tv: { key: 'isTV', color: '#7DD3FC', title: 'Télévision' },
  sleeping: {
    key: 'isSleeping',
    color: '#6366F1',
    title: 'Sommeil',
  },
  silence: { key: 'isSilence', color: '#ee8888', title: 'Silence' },
  as: {
    key: 'isAS',
    color: '#DDDDDD',
    title: 'AS',
  },
  yell: { key: 'isYelling', color: '#FFDD00', title: 'Cris/Gémissements' },
  speak: { key: 'isMonologue', color: '#44AA00', title: 'Monologue' },
  need: { key: 'inNeed', color: '#EE66DD', title: "Besoin d'assistance" },
  machine: { key: 'isMachine', color: '#BB9955', title: 'Machine' },
  awake: { key: 'isAwake', color: '#00FFDD', title: 'Réveil' },
  empty: { key: 'isEmpty', color: '#BB1111', title: 'Absent' },
}

const Bar: React.FC<{
  color: string
  start: number
  end: number
  startTimeStamp: number
  endTimeStamp: number
}> = ({ color, start, end, startTimeStamp, endTimeStamp }) => {
  const range = end - start
  return (
    <div
      className="absolute inset-y-0"
      style={{
        backgroundColor: color,
        left: `${((startTimeStamp - start) / range) * 100}%`,
        width: `${((endTimeStamp - startTimeStamp) / range) * 100}%`,
      }}
      title={`de ${formatMsTime(sec2ms(startTimeStamp))} à ${formatMsTime(
        sec2ms(endTimeStamp),
      )}`}
    />
  )
}

export const RoomStateGraph: React.FC<{
  date: string
  serial: string
  room: string
  start: number
  end: number
}> = ({ date, serial, room, start, end }) => {
  const liveStates = useFirebase(`live/${date}/${serial}`)

  return (
    <div className="flex flex-row items-center gap-2">
      <span
        className="w-12 overflow-hidden text-ellipsis whitespace-nowrap text-right"
        title={room}
      >
        {room}
      </span>
      {liveStates.loading ? (
        <span className="flex-1 text-center">Chargement...</span>
      ) : liveStates.error ? (
        <span className="flex-1 text-center">Erreur</span>
      ) : (
        <RoomStateGraph_ liveStates={liveStates.data} start={start} end={end} />
      )}
    </div>
  )
}

const RoomStateGraph_: React.FC<{
  liveStates: LiveHistory | undefined
  start: number
  end: number
}> = ({ liveStates, start, end }) => {
  if (liveStates === undefined)
    return <span className="flex-1 text-center">Pas de données</span>

  type LiveRoomStateWithEnd = LiveRoomState & {
    endTimeStamp: number
  }
  type LiveHistoryEntry = [FirebaseKey, LiveRoomState]
  type LiveHistoryWithEndEntry = [FirebaseKey, LiveRoomStateWithEnd]

  function sortByTimeStamp(
    [, { timeStamp: timeStampA }]: [unknown, TimeStamped],
    [, { timeStamp: timeStampB }]: [unknown, TimeStamped],
  ) {
    return timeStampA - timeStampB
  }

  function computeEndTimeStamp(
    [key, state]: LiveHistoryEntry,
    index: number,
    array: LiveHistoryEntry[],
  ) {
    const endTimeStamp =
      array[index + 1]?.[1].timeStamp ?? Math.round(ms2sec(Date.now()))
    const liveHistoryWithEndEntry: LiveHistoryWithEndEntry = [
      key,
      { ...state, endTimeStamp },
    ]
    return liveHistoryWithEndEntry
  }

  function isWithinMonitoringRange([_, state]: LiveHistoryWithEndEntry) {
    return state.endTimeStamp >= start && state.timeStamp < end
  }

  function clampToMonitoringRange([key, state]: LiveHistoryWithEndEntry) {
    state.timeStamp = Math.min(Math.max(state.timeStamp, start), end)
    state.endTimeStamp = Math.min(Math.max(state.endTimeStamp, start), end)
    const liveHistoryWithEndEntry: LiveHistoryWithEndEntry = [key, state]
    return liveHistoryWithEndEntry
  }

  const entries = Object.entries(liveStates ?? {})
    .sort(sortByTimeStamp)
    .map(computeEndTimeStamp)
    .filter(isWithinMonitoringRange)
    .map(clampToMonitoringRange)

  return (
    <div className="flex flex-1 flex-col bg-neutral-800">
      {states.map((state) => (
        <div className="relative h-1" key={state}>
          {entries.map(([key, roomState]) =>
            roomState[stateData[state].key] ? (
              <Bar
                key={key}
                color={stateData[state].color}
                startTimeStamp={roomState.timeStamp}
                endTimeStamp={roomState.endTimeStamp}
                start={start}
                end={end}
              />
            ) : null,
          )}
        </div>
      ))}
    </div>
  )
}
