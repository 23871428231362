import React, { useState } from 'react'
import { FacilityDevice } from 'shared/types/live'
import { TimeRange } from 'shared/types/timeRange'
import { Serial } from 'shared/types/utils'
import { dateTimeFromISO } from 'shared/utils/time'
import { asHours } from 'shared/utils/timeRange'
import { collator } from 'shared/utils/web/collator'
import { YellowButton } from '../../components/Button'
import { RoomStateGraph, stateData, states } from './RoomStateGraph'

const RoomStateGraphsReal: React.FC<{
  date: string
  devices: Record<Serial, FacilityDevice>
  monitoringTimeRange: TimeRange
}> = ({ date, devices, monitoringTimeRange }) => {
  const midnight = dateTimeFromISO(date)
  const start = midnight
    .plus({ hour: asHours(monitoringTimeRange.start) })
    .toSeconds()
  const endsOnNextDay = monitoringTimeRange.end <= monitoringTimeRange.start
  const end = midnight
    .plus({
      day: endsOnNextDay ? 1 : 0,
      hour: asHours(monitoringTimeRange.end),
    })
    .toSeconds()

  return (
    <>
      {Object.entries(devices)
        .sort(([_serialA, { room: roomA }], [_serialB, { room: roomB }]) =>
          collator.compare(roomA, roomB),
        )
        .map(([serial, { room }]) => (
          <RoomStateGraph
            key={serial}
            date={date}
            serial={serial}
            room={room}
            start={start}
            end={end}
          ></RoomStateGraph>
        ))}
      <div className="flex flex-row justify-evenly">
        {states.map((state) => (
          <div className="flex flex-row" key={state}>
            <div
              className="mr-2 h-full w-6"
              style={{ backgroundColor: stateData[state].color }}
            />
            <label>{stateData[state].title}</label>
          </div>
        ))}
      </div>
    </>
  )
}

export const RoomStateGraphs: React.FC<{
  date: string
  devices: Record<Serial, FacilityDevice>
  monitoringTimeRange: TimeRange
}> = ({ date, devices, monitoringTimeRange }) => {
  const [showRoomStateGraphs, setShowRoomStateGraphs] = useState(false)

  return (
    <div className="flex flex-col gap-3">
      <YellowButton
        onClick={() => setShowRoomStateGraphs(!showRoomStateGraphs)}
      >
        {showRoomStateGraphs
          ? "Cacher l'état des chambres"
          : "Afficher l'état des chambres"}
      </YellowButton>

      {showRoomStateGraphs && (
        <RoomStateGraphsReal
          date={date}
          devices={devices}
          monitoringTimeRange={monitoringTimeRange}
        />
      )}
    </div>
  )
}
