import { User } from 'common/types'
import React, { createContext, useContext, useEffect } from 'react'
import { serverTimestamp } from 'shared/firebase/serverValue'
import { RELEASE_NAME } from '../constants'
import { update } from '../firebaseMethods'

const userContext = createContext<User | undefined>(undefined)

type Props = {
  user: User
  children?: React.ReactNode
}

export const UserProvider: React.FC<Props> = ({ user, children }) => {
  useEffect(() => {
    update(`userReleases/${user.uid}/web`, {
      version: RELEASE_NAME,
      timeStamp: serverTimestamp(),
    })

    if (window.Android?.onUserLoggedIn) window.Android.onUserLoggedIn(user.uid)
  }, [user.uid])

  return <userContext.Provider value={user}>{children}</userContext.Provider>
}

export const useUser = () => {
  const user = useContext(userContext)
  if (user === undefined) throw new Error('No logged in user')
  return user
}

export const useASUser = () => {
  const user = useUser()
  if (user.role !== 'AS') throw new Error('User is not an AS')
  return user
}

export const useAdminUser = () => {
  const user = useUser()
  if (user.role !== 'admin') throw new Error('User is not an Admin')
  return user
}
