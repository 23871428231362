import { t, Trans } from '@lingui/macro'
import { translateAlertDisplayText } from 'common/alert.i18n'
import { alertListener } from 'common/alertQueries'
import { DateTime } from 'luxon'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { serverTimestamp } from 'shared/firebase/serverValue'
import {
  Alert as Alert_,
  AlertComment,
  AlertType,
  enableAudioPlayer,
  Ownership,
} from 'shared/types/alert'
import { dateTimeFromISO, noonDate } from 'shared/utils/time'
import { cn } from 'shared/utils/web/cn'
import { AudioTextInput } from '../../components/AudioTextInput'
import { Audit } from '../../components/Audit'
import { DialogButton } from '../../components/Button'
import { uploadSound } from '../../components/conversation/fileUploader'
import { Player } from '../../components/Player'
import { Button } from '../../components/ui/Button'
import { useUser } from '../../components/UserProvider'
import { remove, set, update } from '../../firebaseMethods'
import { AlertContext } from './AlertContext'

export const Alert: React.FC<{
  alert: Alert_
  serial: string
  alertId: string
}> = ({ alert, serial, alertId }) => {
  const alertRef = useRef<HTMLDivElement>(null)

  const { notificationAlertId } = useContext(AlertContext)

  const { uid } = useUser()

  const [maxPlayProgress, setMaxPlayProgress] = useState(0)

  const alertDate = dateTimeFromISO(alert.date)
  const date = noonDate(alertDate)

  const onPlayPauseEvent = useCallback(
    async (progress: number) => {
      if (isNaN(progress)) return

      const max = Math.max(progress, maxPlayProgress)

      setMaxPlayProgress(max)

      await set(
        `alerts/${date}/${serial}/${alertId}/listeners/${uid}`,
        alertListener(max),
      )
    },
    [alertId, date, maxPlayProgress, serial, uid],
  )

  const onPlayProgress = useCallback((progress: number) => {
    if (isNaN(progress)) return
    setMaxPlayProgress((maxPlayProgress) => Math.max(progress, maxPlayProgress))
  }, [])

  async function takeAlertOwnership() {
    const ownership: Ownership = {
      uid,
      startTS: serverTimestamp(),
      playProgress: maxPlayProgress,
    }
    await set(`alerts/${date}/${serial}/${alertId}/ownership`, ownership)
  }

  async function releaseAlertOwnership() {
    await remove(`alerts/${date}/${serial}/${alertId}/ownership`)
  }

  async function evaluateAlert(
    text: string | undefined,
    audioBlob: Blob | null,
  ) {
    await update(`alerts/${date}/${serial}/${alertId}/ownership`, {
      endTS: serverTimestamp(),
      playProgress: maxPlayProgress,
    })

    if (!audioBlob && !text) return
    let comment: AlertComment | undefined = undefined

    if (audioBlob) {
      const [audioCommentPath, audioCommentURL] = await uploadSound(audioBlob)
      if (!audioCommentURL) {
        return
      }

      comment = { audioCommentURL, audioCommentPath }
    } else if (text) {
      comment = { comment: text }
    }
    if (!comment) return

    await set(`alerts/${date}/${serial}/${alertId}/ownership/comment`, comment)
  }

  // One time effect that should run only once per alert
  useEffect(() => {
    const doScroll = notificationAlertId === alertId
    if (doScroll)
      alertRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }, [notificationAlertId, alertId])

  return (
    <div
      ref={alertRef}
      className={cn(
        alertBackgroundColor(alert.type),
        'text-alert relative flex flex-col space-y-3 rounded-xl p-3',
      )}
    >
      <div className="flex flex-row flex-wrap items-center space-x-4">
        {alert.auditUrl && (alert.auditUrlExpiration ?? 0) >= Date.now() ? (
          <Audit auditUrl={alert.auditUrl} />
        ) : enableAudioPlayer(alert.type) ? (
          <Player
            soundURI={alert.soundURL}
            onPlayPauseEvent={onPlayPauseEvent}
            onPlayProgress={onPlayProgress}
            isPreloadEnabled
          />
        ) : null}
        <div>
          <b>{alertDate.toLocaleString(DateTime.TIME_SIMPLE)}</b>
        </div>
        <div>{translateAlertDisplayText(alert)}</div>
      </div>
      <AlertHandling
        alert={alert}
        takeAlertOwnership={takeAlertOwnership}
        releaseAlertOwnership={releaseAlertOwnership}
        evaluateAlert={evaluateAlert}
      />
    </div>
  )
}

// Remember to update HTML in HelpDialog accordingly
const AlertHandling: React.FC<{
  alert: Alert_
  takeAlertOwnership: () => void
  releaseAlertOwnership: () => void
  evaluateAlert: (comment: string | undefined, audioBlob: Blob | null) => void
}> = ({ alert, takeAlertOwnership, releaseAlertOwnership, evaluateAlert }) => {
  const [comment, setComment] = useState('')
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)

  const { uid } = useUser()

  if (alert.ownership === undefined) {
    return (
      <div className="my-3 flex flex-row justify-center">
        <DialogButton onClick={takeAlertOwnership}>
          <Trans>Je m'en charge !</Trans>
        </DialogButton>
      </div>
    )
  }

  if (alert.ownership.endTS) {
    const now = DateTime.now()
    const endDateTime = DateTime.fromMillis(alert.ownership.endTS)

    const formattedTimeSinceOwnership = endDateTime.toRelative({ base: now })

    return (
      <div className="text-center">
        <Trans>Alerte traitée {formattedTimeSinceOwnership}</Trans>
      </div>
    )
  }

  if (alert.ownership.uid === uid) {
    return (
      <div className="flex flex-col space-y-3">
        <AudioTextInput
          text={comment}
          onTextChanged={setComment}
          audioBlob={audioBlob}
          onAudioRecorded={setAudioBlob}
          placeholder={t`Votre avis (optionnel)`}
          variant="input"
        />
        <div className="flex flex-row justify-between">
          <Button
            onClick={() => releaseAlertOwnership()}
            variant="outline"
            size="lg"
          >
            <Trans>Annuler</Trans>
          </Button>
          <div className="flex-1" />
          <Button
            onClick={() => evaluateAlert(comment, audioBlob)}
            size="lg"
            variant="submit"
          >
            <Trans>Valider</Trans>
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="text-center">
      <Trans>Alerte prise en charge</Trans>
    </div>
  )
}

function alertBackgroundColor(alertType: AlertType) {
  if (alertType === 'WAKEUP') return 'bg-background-alert-wakeup'
  return 'bg-background-alert'
}
